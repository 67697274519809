import React, { createContext, useCallback, useMemo, useReducer } from "react";

const defaultMenu = {
    open: false,
    dark: false,
    hidden:false,
}

function menuController(state, action) {

    switch (action.type) {
        case 'open':
            return {
                ...state,
                open: action.payload
            }
        case 'dark':
            return {
                ...state,
                dark: action.payload
            }
        case 'hide':
            return {
                ...state,
                hidden:action.payload
            }
        default:
            return state
    }
}



export const MenuContext = createContext(defaultMenu);


export default function MenuProvider({ children }) {
    const [menu, dispatch] = useReducer(menuController, defaultMenu)


    const setOpen = useCallback((state) => {
        dispatch({ type: 'open', payload: state })
    }, [dispatch])

    const setDark = useCallback((state) => {
        dispatch({ type: 'dark', payload: state })
    }, [dispatch])

    const setHide = useCallback((state) => {
        dispatch({ type: 'hide', payload: state })
    }, [dispatch])

    const context = useMemo(() => {
        return { menu, setOpen, setDark,setHide }
    }, [menu,setOpen,setDark,setHide])



    return (
        <MenuContext.Provider value={context} >
            {children}
        </MenuContext.Provider>
    )
}


